import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { httpToUse, ipAdress, localIpAdress, portToUse } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButtonBO';
import CustomButtonBO from '../components/UI/CustomButtonBO';

const GeneralConditionsPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const uid = queryParams.get('uid');
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmationShown, setPasswordConfirmationShown] = useState(false);
    const navigate = useNavigate()
    
    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const togglePasswordConfirmationVisibility = () => {
        setPasswordConfirmationShown(!passwordConfirmationShown);
    };

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
            .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
            .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une majuscule')
            .matches(/[0-9]/, 'le mot de passe doit contenir au moins un chiffre')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Le mot de passe doit contenir au moins un caractère spécial')
            .required('Le mot de passe est requis'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], "La confirmation du mot de passe n'est pas identique")
                .required('La confirmation du mot de passe est requise')
        }),
        onSubmit: (values) => {
            // Replace with your API endpoint and necessary payload
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken
            axios.post(`https://rotpunkttest.fr/api/change-password/?token=${token}&uid=${uid}`, {
            new_password: values.newPassword,
            confirm_password: values.confirmPassword
        })
        .then(response => {
            navigate('/password-changed/')
            // Redirect or perform other actions upon success
        })
        .catch(error => {
            console.error('Error updating password', error);
        });
        },
    });

    return (
        <div className='general_conditions_outercontainer'>
                <div className='bold '>
                Conditions Générales de Gastón{"\n\n"} 
                </div>
                Les Conditions générales gouvernent :{"\n"}

                l’utilisation de cette Application, et,{"\n"}
                tout autre Accord connexe ou relation juridique avec le Propriétaire
                de façon juridiquement contraignante. Les mots commençant par une majuscule sont définis dans les sections correspondantes de ce document.{"\n\n"}


                L’Utilisateur doit lire ce document attentivement.{"\n\n"}

                Bien que l’intégralité de la relation contractuelle relative à ces Produits soit conclue uniquement par le Propriétaire et l’Utilisateur, l’Utilisateur reconnaît et accepte que, lorsqu’il accède à cette Application par l’intermédiaire de l’App Store d’Apple, Apple peut faire appliquer les présentes Conditions en tant que tiers bénéficiaire.

                L'entité suivante fournit cette Application :{"\n"}

                Gastón, 9 rue Nouvelle, Annecy, 74000.{"\n\n"}

                <div className='bold '>
                    Courriel de contact du Propriétaire :
                </div> 
                daniel@gaston-grimpe.com {"\n\n\n\n"}

                <div className='bold '>
                    Informations concernant cette Application{"\n\n"}
                </div>
                Gastón est une application permettant aux grimpeurs de salle d'escalade de partager leurs séances, progresser et rencontrer de nouvelles personnes.{"\n\n\n\n"}

                <div className='bold '>
                    L’essentiel de ce que les Utilisateurs doivent savoir en un coup d’œil{"\n\n"}
                </div>

                Veuillez noter que certaines dispositions de ces Conditions générales pourraient ne s’appliquer qu’à certaines catégories d’Utilisateurs. En particulier, certaines des dispositions peuvent s’appliquer uniquement aux Consommateurs ou, au contraire, uniquement aux Utilisateurs qui ne sont pas qualifiés de Consommateurs. De telles limites sont toujours mentionnées expressément dans chaque clause affectée. En l’absence de telle mention, les clauses s’appliquent à tous les Utilisateurs.
                Le droit de rétractation ne s’applique qu’aux Consommateurs Européens.{"\n\n\n\n"}


                <div className='bold '>
                    CONDITIONS GÉNÉRALES D'UTILISATION {"\n\n"}
                </div>
                A moins que précisé autrement, les conditions d’utilisation détaillées dans cette section s’appliquent généralement pendant l’utilisation de cette Application.

                Des conditions d’utilisation uniques ou additionnelles peuvent s’appliquer à des condives particuliers et sont alors indiquées additionnellement au sein de ce document.

                En utilisant cette Application, les Utilisateurs s’engagent à respecter les conditions suivantes :

                Il n’y a pas de restrictions pour les Utilisateurs en termes de leur statut de Consommateur ou d’Utilisateurs Professionnels.
                Les Utilisateurs ne sont pas situés dans un pays visé par un embargo du Gouvernement États-Unis, ou qui a été désigné de pays « soutenant le terrorisme » par le Gouvernement des États-Unis ;
                Les Utilisateurs ne sont sur aucune liste de parties interdites ou restreintes du Gouvernement des États-Unis ;{"\n\n\n\n"}
    
                <div className='bold '>
                    Création d’un compte{"\n\n"}
                </div>
                Afin d’utiliser le Service, les Utilisateurs doivent s’inscrire ou créer un compte Utilisateur en communiquant toutes les données ou informations nécessaires de manière complète et honnête.

                Si les Utilisateurs ne procèdent pas ainsi, le Service sera indisponible.

                Les Utilisateurs sont tenus de garder leurs identifiants de connexion confidentiels et de garantir leur sécurité. C’est la raison pour laquelle les Utilisateurs sont également tenus de choisir des mots de passe qui respectent les normes de résistance les plus élevées autorisées par cette Application.

                En s’enregistrant, les Utilisateurs se reconnaissent pleinement responsables pour toutes les activités qui ont lieu sous leurs noms d’utilisateur et mot de passe. Il est requis des Utilisateurs d’informer le Propriétaire immédiatement et sans équivoque, en se servant des coordonnées indiquées dans ce document, s’ils ont raison de penser que leurs informations personnelles, et notamment les comptes d’utilisateurs, les autorisations d’accès ou les données personnelles, ont été violés, indûment divulgués ou volés.{"\n\n\n\n"}

                <div className='bold '>
                    Conditions à la création d’un compte{"\n\n"}
                </div>
                La création de comptes d’Utilisateurs sur cette Application est assujettie aux conditions exposées ci-dessous. En s’enregistrant, les Utilisateurs s’engagent à respecter de telles conditions.

                Les Comptes créés par des bots ou d’autres méthodes automatisées ne sont pas permis.
                A moins que précisé autrement, chaque Utilisateur ne doit enregistrer qu’un seul compte.
                A moins qu’autorisé expressément, un compte d’Utilisateur ne peut pas être partagé avec d’autres personnes.{"\n\n\n\n"}
                
                <div className='bold '>
                    Fermeture du compte{"\n\n"}
                </div>
                Les Utilisateurs peuvent fermer leur compte et arrêter d’utiliser le Service à tout moment en :

                En contactant directement le Propriétaire avec les coordonnées fournies dans ce document.{"\n\n\n\n"}
    
                <div className='bold '>
                    Suspension et suppression de compte{"\n\n"}
                </div>
                Le Propriétaire se réserve le droit, à sa seule discrétion, de suspendre ou de supprimer à tout moment et sans avis, les comptes d’Utilisateurs considérés inappropriés, en infraction ou en violation de ces Conditions générales.

                La suspension ou la suppression de comptes d’Utilisateurs ne permettra pas aux Utilisateurs de faire des demandes d’indemnisation, de dommages et intérêts ou de remboursement.

                La suspension ou la suppression de comptes due à des causes imputables à l’Utilisateur ne dispense pas l’Utilisateur de payer tout frais ou prix applicables.{"\n\n\n\n"}

                <div className='bold '>
                    Contenu sur cette Application {"\n\n"}
                </div>

                Sauf indication contraire ou clairement identifiable, tout contenu accessible sur cette Application appartient au Propriétaire ou à ses concédants de licence, ou est présenté par ceux-ci.

                Le Propriétaire s’efforce de s’assurer que le contenu présenté sur cette Application n’enfreint aucune disposition légale en vigueur ni aucun droit de tiers. Il n’est cependant pas toujours possible de parvenir à ce résultat.
                Dans de tels cas, sans préjudice aux prérogatives légales des Utilisateurs pour mettre leurs droits en application, les Utilisateurs sont priés préférablement de signaler toute plainte en se servant des coordonnées fournies dans ce document.{"\n\n\n\n"}

                <div className='bold '>
                    Droits concernant le contenu sur cette Application{"\n\n"}
                </div>

                Le Propriétaire détient et se réserve tous les droits de propriété intellectuelle pour ce contenu.

                Par conséquent, les Utilisateurs ne peuvent pas utiliser ce contenu d’une manière qui n’est pas nécessaire ou implicite dans l’usage adéquat du Service.

                Particulièrement, mais sans s’y limiter, les Utilisateurs ne peuvent pas copier, télécharger, partager (au-delà des limites avancées ci-dessous), modifier, traduire, transformer, publier, transmettre, vendre, sous-licencier, modifier, transférer/assigner à des tiers ou créer un travail dérivé du contenu accessible sur cette Application, ou de permettre tout tiers de le faire à travers l’Utilisateur ou leur appareil, même sans que l’Utilisateur en ait connaissance.

                Lorsque expressément indiqué sur cette Application, l’Utilisateur peut télécharger, copier et/ou partager du contenu accessible sur cette Application, pour un usage uniquement personnel et non-commercial et à condition que les attributions de droits d’auteur et toutes les autres attributions demandées par le Propriétaire sont correctement mises en place.

                Toute limitation statutaire applicable, ou exception au droit d’auteur restera intact.{"\n\n\n\n"}

                <div className='bold '>
                    Contenu fourni par les Utilisateurs {"\n\n"}
                </div>

                Le Propriétaire permet aux Utilisateurs de télécharger, partager ou fournir leur propre contenu sur cette Application.

                En fournissant du contenu à cette Application, les Utilisateurs confirment être légalement autorisés de ce faire et qu’aucune disposition statutaire ou qu’aucun droit de tiers ne sont enfreints.

                Les Utilisateurs reconnaissent et acceptent qu’en fournissant leur propre contenu sur cette Application ils accordent une licence non-exclusive, mondiale, entièrement libérée et libre de redevance, irrévocable, perpétuelle (ou pour l’entière période de protection), sous-licenciable et transférable, d’utiliser, accéder, conserver, reproduire, modifier, distribuer, publier, traiter dans des travaux dérivés, retransmettre, diffuser en streaming, transmettre ou exploiter autrement un tel contenu pour fournir et faire la promotion de son Service avec tous medias ou moyens.

                Dans les limites du droit applicable, les Utilisateurs renoncent à tout droit moral en connexion avec le contenu qu’ils fournissent à cette Application.

                Les Utilisateurs reconnaissent, acceptent et confirment que tout contenu qu’ils fournissent à travers cette Application sera disponible sous réserve des mêmes Conditions générales avancées pour le contenu sur cette Application.

                Les Utilisateurs sont exclusivement responsables de tout contenu qu’ils téléchargent, publient, partagent ou fournissent à travers cette Application.

                Les Utilisateurs reconnaissent et acceptent que le Propriétaire filtre et modère un tel contenu après que celui-ci a été mis à disposition.

                Ainsi, le Propriétaire se réserve le droit de refuser, retirer, supprimer ou bloquer de tels contenus à sa propre discrétion et de refuser, sans avis préalable, l’accès à cette Application pour l’Utilisateur qui télécharge, s’il considère qu’un tel contenu enfreint les présentes Conditions générales, toute disposition légale ou droit de tiers, ou représente autrement un risque pour les Utilisateurs, les tiers, le Propriétaire et/ou la disponibilité du Service.

                Le retrait, la suppression ou le blocage du contenu ne permettra pas aux Utilisateurs ayant fourni un tel contenu ou qui en sont responsables, de réclamer une compensation, des dommages-intérêts ou un remboursement.

                Les Utilisateurs s’engagent à protéger le Propriétaire contre toute réclamation revendiquée et/ou tous dommages soufferts à cause du contenu qu’ils ont fourni à travers cette Application.{"\n\n\n\n"}

            <div className='bold '>
                Retrait du contenu de parties de cette Application accessible à travers l’App Store{"\n\n"}
            </div>

            Si le contenu reporté est considéré répréhensible, il sera retiré dans les 24 heures et l’Utilisateur ayant fourni ce contenu sera barré de l’utilisation du Service.{"\n\n\n\n"}

            <div className='bold '>
                Accès aux ressources externes{"\n\n"}
            </div>

            A travers cette Application les Utilisateurs peuvent avoir accès aux ressources externes fournies par des tiers. Les Utilisateurs reconnaissent et acceptent que le Propriétaire n’a pas de contrôle sur de telles ressources et ne sera pour cela pas responsable pour le contenu et la disponibilité.

            Les conditions applicables aux ressources fournies par des tiers, y compris celles applicables à l'octroi éventuel de droits dans le contenu découlent des Conditions générales de tous tiers ou, en leur absence du droit statutaire applicable.{"\n\n\n\n"}

            <div className='bold '>
                Utilisation acceptable{"\n\n"}
            </div>

            Cette Application et le Service ne peuvent être utilisés que dans le cadre de ce pour lequel ils ont été fournis, sous ces Conditions générales et le Droit applicable.

            Les Utilisateurs seront seuls responsables de s’assurer que leur utilisation de cette Application et/ou du Service n’enfreint aucune loi en vigueur, règlement ou droits de tiers.

            C’est pourquoi, le Propriétaire se réserve le droit de prendre toute mesure appropriée pour protéger ses intérêts légitimes, et notamment de refuser l’accès de l’Utilisateur à cette Application ou le Service, de mettre fin aux contrats, de reporter toute faute commise à travers cette Application ou le Service aux autorités compétentes – telles que les autorités judiciaires ou administratives - lorsque les Utilisateurs se livrent ou sont soupçonnés de se livrer aux activités suivantes :

            une violation de lois ou de règlements en vigueur et/ou de ces Conditions générales ;
            l’atteinte à tout droit de tiers ;
            une atteinte considérable aux intérêts légitimes du Propriétaire ;
            une offense au Propriétaire ou à un tiers{"\n\n\n\n"}

            <div className='bold '>
                CONDITIONS GÉNÉRALES DE VENTE{"\n\n"}
            </div>


            <div className='bold '>
                Fourniture de données personnelles{"\n\n"}
            </div>


            Pour recevoir ou accéder à certains des Produits fournis par le biais de cette Application dans le cadre du Service, l’Utilisateur peut devoir fournir ses données personnelles comme indiqué sur cette Application.{"\n\n\n\n"}

            <div className='bold '>
                Produits payés{"\n\n"}
            </div>

            Certains Produits fournis sur cette Application, constituant une partie du Service, sont payants.

            Les frais, la durée et les conditions applicables à l’achat de tels Produits sont décrits ci-dessous et dans les sections correspondantes de cette Application.{"\n\n\n\n"}

            <div className='bold '>
                Description de produit{"\n\n"}
            </div>
            Les prix, les descriptions ou la disponibilité des Produits sont décrits dans les parties respectives de cette Application et sont susceptibles d’être modifiés sans préavis.

            Bien que les Produits sur cette Application soient présentés avec la plus grande précision technique possible, les représentations sur cette Application par tout moyen (y compris, selon le cas, les éléments graphiques, les images, les couleurs, les sons) ne sont données qu’à titre indicatif et n’impliquent aucune garantie quant aux caractéristiques du Produit acheté.

            Les caractéristiques du Produit choisi seront décrites lors du processus d’achat.{"\n\n\n\n"}

            <div className='bold '>
                Procédure d’achat{"\n\n"}
            </div>
            Toute étape du choix du Produit au placement de la commande forme partie de la procédure d’achat.

            La procédure d’achat inclue ces étapes :

            Les Utilisateurs doivent choisir le Produit désiré et vérifier leur sélection d’achats.
            Après avoir revu les informations présentées dans la sélection d’achat, les Utilisateurs peuvent placer une commande en la soumettant.{"\n\n\n\n"}
        
            <div className='bold '>
                Passation de commande{"\n\n"}
            </div>

            Lorsque l’Utilisateur passe une commande, ce qui suit s’applique :

            La passation d’une commande détermine la conclusion d’un contrat et créé par conséquent l’obligation pour l’Utilisateur de payer le prix, les taxes et les frais et dépenses possibles, comme précisé sur la page de commande.
            Lorsque le Produit acheté requiert une action de l’Utilisateur, telle que la fourniture d’informations ou de données personnelles, de précisions ou de souhaits particuliers, la passation de commande créé une obligation pour l’Utilisateur de coopérer en conséquence.
            Une fois la commande passée, les Utilisateurs recevront un reçu confirmant que l’ordre a été reçu.
            Toutes notifications liées au processus d’achat décrit seront envoyées à l’adresse mail fournie par l’Utilisateur pour cette raison.{"\n\n\n\n"}

            <div className='bold '>
                Prix{"\n\n"}
            </div>

            Les Utilisateurs sont informés pendant le processus d’achat et avant la soumission de la commande, de tous frais et prix (y compris, si présents, les coûts de livraison) dont ils seront facturés.

            Les Prix sont présentés sur cette Application :

            soit excluant soit comprenant tous les frais, taxes et prix applicables, selon l’Utilisateur naviguant ;{"\n\n\n\n"}
            
            <div className='bold '>
                Méthodes de paiement{"\n\n"}
            </div>

            Les informations liées aux méthodes de paiement acceptées sont rendues accessibles pendant le processus d’achat.

            Certaines méthodes de paiement peuvent seulement être accessibles sur conditions ou frais additionnel. Dans de tels cas l’information qui y est connectée peut être trouvée dans la section dédiée de cette Application.

            Tous les paiements sont traités indépendamment à travers des services de tiers. C’est pourquoi cette Application ne collecte pas d’information de paiement – telle que les informations de carte de crédit – mais reçoit seulement une notification une fois que le paiement a été effectué avec succès.

            Si un paiement par les moyens disponibles échoue ou est refusé par le fournisseur de service de paiement, le Propriétaire ne sera soumis à aucune obligation d’exécuter la commande. En cas d’échec du paiement ou de paiement refusé, le Propriétaire se réserve le droit de réclamer à l’Utilisateur le remboursement des éventuels frais ou dommages en découlant.{"\n\n\n\n"}

            <div className='bold '>
                Rétention de propriété du Produit{"\n\n"}
            </div>

            Jusqu’à ce que le paiement du prix total d’achat soit reçu par le Propriétaire, aucun Produit commandé ne deviendra la propriété de l’Utilisateur.{"\n\n\n\n"}

            <div className='bold '>
                Rétention des droits d’utilisation{"\n\n"}
            </div>

            Les Utilisateurs n’acquièrent pas de droits d’utilisation du Produit acheté jusqu’à ce que le prix d’achat total a été reçu par le Propriétaire.{"\n\n\n\n"}

            <div className='bold '>
                Livraison{"\n\n"}
            </div>

            Les livraisons sont effectuées à l’adresse indiquée par l’Utilisateur et de la manière spécifiée dans le récapitulatif de la commande.

            Lors de la livraison, il est conseillé aux Utilisateurs de vérifier le contenu de la livraison et de signaler toute anomalie sans délai injustifié, en se servant des coordonnées fournies dans le présent document ou de la manière indiquée dans le bon de livraison. L’Utilisateur peut refuser d’accepter un paquet si celui-ci est visiblement abimé.

            Les biens sont livrés vers les pays ou territoires précisés dans la section correspondante de cette Application.

            Les horaires de livraison sont précisés sur cette Application ou durant le processus d’achat.{"\n\n\n\n"}

            <div className='bold '>
                Livraison échouée{"\n\n"}
            </div>

            Le Propriétaire ne peut pas être tenu pour responsable des erreurs de livraison causées par des inexactitudes ou des lacunes dans l’exécution de l’ordre d’achat par l’Utilisateur, ou des dommages ou retards après la passation à un porteur qui est arrangée par l’Utilisateur et n’est pas proposée ou recommandée par le Propriétaire.

            Si les biens ne sont pas reçus ou collectés au moment ou avant la date limite spécifiée, les biens seront rendus au Propriétaire, qui contactera l’Utilisateur pour organiser une seconde tentative de livraison ou s’accorder sur le plan d’action future.

            Sauf si convenu autrement, toute tentative de livraison à partir de la seconde sera à la charge de l’Utilisateur.{"\n\n\n\n"}

            <div className='bold '>
                Livraison de contenus digitaux{"\n\n"}
            </div>

            A moins que déclaré autrement, le contenu digital acheté sur cette Application est livré à travers le téléchargement sur l’appareil choisi par l’Utilisateur.

            Les Utilisateurs reconnaissent et acceptent qu’afin de commander un téléchargement et/ou utilisation du Produit, appareil(s) visé(s) et son logiciel respectif (comprennant les systèmes opérationels) doivent être légaux, communément utilisés, à jour, et compatible avec de telles normes du marché.

            Les Utilisateurs reconnaissent et acceptent que la capacité à télécharger le Produit acheté peut être limité dans le temps et l’espace.{"\n\n\n\n"}

            <div className='bold '>
                Droits d’Utilisateur{"\n\n"}
            </div>



            <div className='bold '>
                Droit de rétractation{"\n\n"}
            </div>
            A moins que des exceptions s’appliquent, les Utilisateurs seront éligibles pour se retirer du contrat dans la période spécifiée ci-dessous (généralement 14 jours), pour toute raison et sans motivation. Les Utilisateurs peuvent en apprendre plus sur les conditions de rétractation dans cette section.{"\n\n\n\n"}

            <div className='bold '>
                A qui le droit de rétractation s’applique{"\n\n"}
            </div>
            À moins qu’une quelconque exception applicable soit mentionnée ci-dessous, les Utilisateurs qui sont des Consommateurs Européens se voient accorder un droit légal de rétractation en vertu des règles de l’UE, leur permettant de se rétracter de contrats conclus en ligne (les contrats à distance) dans le délai précisé applicable à leur cas, pour toute raison et sans justification.

            Les Utilisateurs qui n’entrent pas dans cette qualification, ne peuvent pas bénéficier des droits décrits dans cette section.{"\n\n\n\n"}

            <div className='bold '>
                Exercer le droit de rétractation{"\n\n"}
            </div>

            Afin d’exercer leur droit de rétractation, les Utilisateurs doivent envoyer une déclaration sans équivoque de leur intention de se retirer du contrat.

            A cette fin, les Utilisateurs peuvent utiliser un modèle de rétractation accessible depuis la section « définition » de ce document. Les Utilisateurs sont, cependant, libres d’exprimer leur intention de se rétracter de ce contrat en faisant une déclaration sans équivoque de toute autre façon adéquate. Afin de respecter la date limite dans laquelle ils peuvent exercer un tel droit, les Utilisateurs doivent envoyer l’avis de rétractation avant que la période de rétraction n’expire.

            Quand expire la période de rétractation ?

            Concernant l’achat de biens, la période de rétractation expire 14 jours après le jour auquel l’Utilisateur ou un tiers – autre que le porteur et désigné par l’Utilisateur – prend possession des biens en personne.

            Concernant l’achat de plusieurs biens commandés ensemble mais livrés séparément ou en cas d’achat d’un bien unique consistant de lots multiples ou des pièces livrées séparément, la période de rétractation expire 14 jours après le jour auquel l’Utilisateur ou un tiers - autre que le porteur ou la personne désignés par l’Utilisateur - prend possession du dernier bien ou lot, ou de la dernière pièce, en personne.{"\n\n\n\n"}

            <div className='bold '>
                Effets de la rétractation{"\n\n"}
            </div>

            Les Utilisateurs qui se sont rétractés correctement d’un contrat seront remboursés par le Propriétaire pour tous les paiements faits au Propriétaire, y compris, si présent, ceux couvrant la livraison.

            Cependant, tout coût additionnel résultant du choix d’une méthode de livraison autre que le type de livraison standard la moins chère offerte par le Propriétaire, ne sera pas remboursée.

            Un tel remboursement sera fait sans délai injustifié et, dans tous les cas, pas plus tard que 14 jours à compter du jour où le Propriétaire est informé de la décision de l’Utilisateur de se retirer du contrat. Sauf si convenu autrement avec l’Utilisateur, les remboursements seront faits en utilisant le même moyen de paiement qu’utilisé pour traiter la transaction initiale. Dans tous les cas, l’Utilisateur n’encourra pas de coûts ou de frais en résultat de tels remboursements.

            … sur l’achat de biens physiques
            A moins que le Propriétaire ait offert de collecter les biens, l’Utilisateur renverra le bien ou les transmettra au propriétaire, ou la personne autorisée par ce dernier de recevoir les biens, sans délai injustifié et dans tous les cas dans les 14 jours à compter du jour où ils ont communiqué leur décision de se retracter du contrat.

            Les dates limites sont respectées si les biens sont transmis au porteur, ou autrement rendus tel qu’indiqué ci-dessus, avant l’expiration de la période de 14 jours pour rendre les biens. Les remboursements peuvent être retenus jusqu’à la réception de biens, ou jusqu’à ce que les Utilisateurs aient fourni la preuve d’avoir rendu le bien, suivant la solution la plus rapide.

            Les Utilisateurs seront uniquement responsables pour une valeur diminuée des biens résultant de la manutention des biens en dehors de ce qui est nécessaire pour établir leur nature, caractéristique et fonctionnement.

            Les coûts de restitution des biens seront à la charge de l’Utilisateur{"\n\n\n\n"}

            <div className='bold '>
                UK User rights{"\n\n"}
            </div>


            <div className='bold '>
                Right to cancel{"\n\n"}
            </div>

            Unless exceptions apply, Users who are Consumers in the United Kingdom have a legal right of cancellation under UK law and may be eligible to withdraw from contracts made online (distance contracts) within the period specified below (generally 14 days), for any reason and without justification.

            Users that do not qualify as Consumers, cannot benefit from the rights described in this section. Users can learn more about the cancellation conditions within this section.{"\n\n\n\n"}

            <div className='bold '>
                Exercising the right to cancel{"\n\n"}
            </div>

            To exercise their right to cancel, Users must send to the Owner an unequivocal statement of their intention to withdraw from the contract. To this end, Users may use the model withdrawal form available from within the “definitions” section of this document. Users are, however, free to express their intention to withdraw from the contract by making an unequivocal statement in any other suitable way. In order to meet the deadline within which they can exercise such right, Users must send the withdrawal notice before the cancellation period expires. When does the cancellation period expire?

            Regarding the purchase of goods, the cancellation period expires 14 days after the day on which the User or a third party – other than the carrier and designated by the User – takes physical possession of the goods.
            Regarding the purchase of several goods ordered together but delivered separately or in case of purchase of a single good consisting of multiple lots or pieces delivered separately, the cancellation period expires 14 days after the day on which the User or a third party – other than the carrier and designated by the User – acquires physical possession of the last good, lot or piece.{"\n\n\n\n"}

            <div className='bold '>
                Effects of cancellation{"\n\n"}
            </div>
            Users who correctly withdraw from a contract will be reimbursed by the Owner for all payments made to the Owner, including, if any, those covering the costs of delivery.

            However, any additional costs resulting from the choice of a particular delivery method other than the least expensive type of standard delivery offered by the Owner, will not be reimbursed.

            Such reimbursement shall be made without undue delay and, in any event, no later than 14 days from the day on which the Owner is informed of the User’s decision to withdraw from the contract. Unless otherwise agreed with the User, reimbursements will be made using the same means of payment as used to process the initial transaction. In any event, the User shall not incur any costs or fees as a result of such reimbursement.

            …ON THE PURCHASE OF PHYSICAL GOODS
            Unless the Owner has offered to collect the goods, Users shall send back the goods or hand them over to the Owner, or to a person authorized by the latter to receive the goods, without undue delay and in any event within 14 days from the day on which they communicated their decision to withdraw from the contract.

            The deadline is met if the goods are handed to the carrier, or otherwise returned as indicated above, before the expiration of the 14-days-period for returning the goods. The reimbursement may be withheld until reception of the goods, or until Users have supplied evidence of having returned the goods, whichever is the earliest.

            Users shall only be liable for any diminished value of the goods resulting from the handling of the goods outside of that which is necessary to establish their nature, characteristics and functioning.

            The costs of returning the goods are borne by the User.{"\n\n\n\n"}

            
            <div className='bold '>
                Garanties{"\n\n"}
            </div>


            <div className='bold '>
                Garantie légale de conformité des biens en droit de l’UE{"\n\n"}
            </div>

            En droit de l’UE, pendant une période minimum de deux ans à compter de la livraison, les professionnels garantissent la conformité des biens qu’ils vendent aux Consommateurs.

            Lorsque les Utilisateurs sont qualifiés de Consommateurs Européens, la garantie légale de la conformité pour des biens s’applique aux articles accessibles sur cette Application en accord avec les lois du pays de leur résidence habituelle.

            Les lois nationales d’un tel pays peuvent garantir des droits plus étendus aux Utilisateurs.

            Les Consommateurs qui ne sont pas qualifiés d’Européens peuvent bénéficier de garanties légales de droits de conformité en accord avec le Droit du pays de leur résidence habituelle.{"\n\n\n\n"}

            <div className='bold '>
                Garantie légale de conformité des Produits Numériques en droit de l’UE{"\n\n"}
            </div>

            En droit de l’UE, les professionnels garantissent, pendant une durée minimale de deux ans à compter de la livraison ou, dans le cas des Produits Numériques fournis de manière continue pendant plus de deux ans, pendant toute la durée de la fourniture, la conformité des Produits Numériques qu’ils fournissent aux Consommateurs.

            Lorsque l’Utilisateur a la qualité de Consommateur Européen, la garantie légale de conformité s’applique aux Produits Numériques disponibles sur cette Application conformément aux lois du pays de sa résidence habituelle.

            Les lois nationales de ce pays peuvent conférer à l’Utilisateur des droits plus étendus.

            Conformity to contract for Consumers in the United Kingdom
            Users in the United Kingdom qualifying as Consumers have a right to receive goods that are in conformity with the contract.{"\n\n\n\n"}


            <div className='bold '>
                Responsabilité et indemnisation{"\n\n"}
            </div>


            Sauf lorsqu’il en est explicitement stipulé autrement ou convenu autrement avec les Utilisateurs, la responsabilité du Propriétaire en cas de dommages liés à l’exécution de l’Accord est exclue, limitée et/ou réduite au maximum, dans la mesure où la loi applicable le permet.{"\n\n\n\n"}

            <div className='bold '>
                Indemnisation {"\n\n"}
            </div>

            L’Utilisateur accepte d’indemniser et de garantir le Propriétaire et ses filiales, affiliés, dirigeants, administrateurs, mandataires, co-marqueurs et employés à raison de toute réclamation ou demande – y compris notamment les coûts et frais d’avocat – faite par un tiers en raison ou en relation avec une violation de ces Conditions générales, des droits de tiers ou de dispositions légales liées à l’utilisation des Services par l’Utilisateur ou ses affiliés, dirigeants, administrateurs, mandataires, co-marqueurs, partenaires et employés dans la mesure où la loi applicable le permet.

            Les dispositions qui précèdent s’appliquent également aux éventuels recours exercés par des tiers (en ce compris, notamment, les clients du Propriétaire) contre le Propriétaire, en lien avec des Produits numériques fournis par l’Utilisateur, tels que, par exemple, des recours pour défaut de conformité.{"\n\n\n\n"}

            <div className='bold '>
                Limitation de responsabilité pour les activités d’Utilisateurs sur cette Application{"\n\n"}
            </div>

            Sauf déclaration contraire explicite, et sans préjudice de la loi applicable, les Utilisateurs n’ont pas le droit de réclamer des dommages-intérêts au Propriétaire (ou à toute personne physique ou morale agissant pour son compte).

            Cela ne s’applique pas aux atteintes à la vie, à la santé ou à l’intégrité physique, aux dommages qui résultent d’un manquement à des obligations contractuelles importantes telles que toute obligation strictement nécessaire pour atteindre l’objectif du contrat, et/ou aux dommages intentionnels ou résultant d’une négligence grave, du moment que cette Application a été utilisé correctement et à bon escient par l’Utilisateur.

            A moins que les dommages aient été causés intentionnellement ou par une grossière négligence, ou s’ils affectent la vie, la santé, ou l’intégrité physique, le Propriétaire ne sera responsable que jusqu’à des dommages typiques ou prévisibles au moment que le contrat a été conclu.{"\n\n\n\n"}

            <div className='bold '>
                Dispositions communes{"\n\n"}
            </div>

            
            <div className='bold '>
                Clause de non-renonciation{"\n\n"}
            </div>

            Le fait que le Propriétaire ne fasse pas valoir un droit ou une disposition dans le cadre des présentes Conditions ne saurait constituer une renonciation à ce droit ou cette disposition. Aucune renonciation ne saurait être considérée comme une renonciation supplémentaire ou continue à la présente condition ou à n’importe quelle autre.{"\n\n\n\n"}

            <div className='bold '>
                Interruption de service{"\n\n"}
            </div>

            Afin d’assurer le meilleur niveau de service possible, le Propriétaire se réserve le droit d’interrompre le Service pour procéder à une maintenance, à des mises à jour du système ou à tout autre changement, en informant les Utilisateurs correctement.

            Dans les limites du droit, le Propriétaire peut aussi décider de suspendre ou de mettre fin au Service tout entier. S’il est mis fin au Service, le Propriétaire coopérera avec les Utilisateurs pour leur permettre de retirer des Données personnelles ou des informations et respectera les droits des Utilisateurs en matière de poursuite de l’utilisation du produit et/ou de dédommagement, ainsi que le prévoit la loi applicable.

            De plus, le Service peut ne pas être accessible à cause de raisons échappant au contrôle raisonnable du Propriétaire, telles que des cas de « force majeure » (défaillances des infrastructures ou pannes de courant etc.).{"\n\n\n\n"}

            <div className='bold '>
                Revente de Service{"\n\n"}
            </div>

            Les Utilisateurs ne peuvent pas reproduire, dupliquer, copier, vendre, revendre ou exploiter toute portion de cette Application et de ces Services sans la permission exprès préalable, accordée soit directement ou à travers un programme de revente légitime.{"\n\n\n\n"}

            <div className='bold '>
                Politique de confidentialité{"\n\n"}
            </div>

            Pour plus d’information sur l’utilisation de leur Données Personnelles, les utilisateurs peuvent se référer à la politique de confidentialité de cette Application.{"\n\n\n\n"}

            <div className='bold '>
                Droits de propriété intellectuelle{"\n\n"}
            </div>

            Sans préjudice à toute disposition particulière de ces Conditions générales, tous droits d’auteur, tels que les marques, les brevets et les droits de design associés à cette Application sont la propriété exclusive du Propriétaire ou de ces concédants de licence sont sujets à la protection accordée par les lois applicables ou les traités internationaux liés à la propriété intellectuelle.

            Toutes marques commerciales – nominales ou figurative – et toutes autres marques, noms de commerce, marque de mots, illustrations, images, ou logos apparaissant en connexion avec cette Application sont, et restent, la propriété exclusive du Propriétaire ou de ces concédants de licence et sont sujets à la protection accordée par les lois applicables ou les traités internationaux liés à la propriété intellectuelle.{"\n\n\n\n"}

            <div className='bold '>
                Modification des présentes Conditions générales{"\n\n"}
            </div>
            Le Propriétaire se réserve le droit de modifier ou autrement de rectifier ces Conditions générales à tout moment. Dans de tels cas, le Propriétaire informera correctement l’Utilisateur de ces modifications.

            Ces modifications n’auront d’incidence sur la relation avec l’Utilisateur qu’à compter de la date communiquée à l’Utilisateur.

            La poursuite de l’utilisation du Service signifiera l’acceptation par l’Utilisateur des Conditions générales révisées. Si l’Utilisateur ne souhaite pas être lié par ces modifications, il doit cesser d’utiliser le Service et peut mettre fin à l’Accord.

            La version précédente applicable régira la relation avant l’acceptation par l’Utilisateur. L’Utilisateur peut obtenir toute version précédente auprès du Propriétaire.

            S’il en a l’obligation légale, le Propriétaire informera les Utilisateurs par avance de la date à laquelle les Conditions générales modifiées entreront en vigueur.{"\n\n\n\n"}

            <div className='bold '>
                Assignation de contrat{"\n\n"}
            </div>

            Le Propriétaire se réserve le droit de transférer, assigner, disposer par novation, ou sous-contracter tout ou tous droits ou obligations sous ces Conditions générales, en prenant les intérêts légitimes de l’Utilisation en compte.
            Les dispositions concernant les changements de ces Conditions seront appliquées en conséquence.

            Les Utilisateurs ne peuvent pas assigner ou transférer leurs droits ou obligations sous ces Conditions générales de toute manière, sans l’autorisation écrite du Propriétaire.{"\n\n\n\n"}
            <div className='bold '>
                Contacts{"\n\n"}
            </div>

            Toute communication liée à l’utilisation de cette Application doit être envoyée à l’aide des coordonnées citées dans ce document.{"\n\n\n\n"}

            <div className='bold '>
                Divisibilité{"\n\n"}
            </div>

            Dans le cas où toute provision de ces Conditions générales pourrait être considérée invalide ou inapplicable sous le Droit applicable, l’invalidité ou l’inapplicabilité d’une telle disposition n’affectera pas la validité des dispositions restantes, qui garderont un plein effet.{"\n\n\n\n"}

            <div className='bold '>
                Utilisateurs de l’UE{"\n\n"}
            </div>

            Dans le cas où toute disposition de ces Conditions serait ou serait considérée comme nulle, invalide ou inapplicable, les parties feront de leur mieux pour trouver, de façon amiable, un accord sur des dispositions valides et applicables substituant ainsi les parties invalides, nulles ou inapplicables.
            A défaut de ce-faire, les dispositions nulles, invalides ou inapplicables seront remplacées par les dispositions statutaires applicables, si cela est permis ou affirmé par la loi en vigueur.
            Sans préjudice à ce qui précède, la nullité, l’invalidité ou l’impossibilité d’appliquer une disposition particulière de ces Conditions ne rendra pas nul l’entier Accord, à moins que les dispositions divisées ne soient essentielles à l’Accord, ou d’une telle importance que les parties n’auraient pas conclu le contrat si elles avaient su que la disposition n’était pas valide, ou dans le cas où les dispositions restantes se traduiraient en une épreuve inacceptable pour une des parties.{"\n\n\n\n"}
            
            <div className='bold '>
                Loi applicable{"\n\n"}
            </div>

            Ces Conditions sont gouvernées par le droit de l’endroit où le Propriétaire est basé, tel qu’est décrit dans la section pertinente de ce document, sans tenir compte des principes de conflits de lois.{"\n\n\n\n"}

            <div className='bold '>
                Primauté du droit national{"\n\n"}
            </div>

            Cependant, indépendamment de ce qui précède, si le droit du pays où est situé l’Utilisateur prévoit des normes applicables de protection des consommateurs plus strictes, ces normes plus strictes prévalent.{"\n\n\n\n"}

            <div className='bold '>
                Exception pour les Consommateurs situés en Suisse{"\n\n"}
            </div>

            Si l’Utilisateur a la qualité de Consommateur en Suisse, le droit suisse s’applique.{"\n\n\n\n"}

            <div className='bold '>
                Lieu de la juridiction compétente{"\n\n"}
            </div>

            La compétence exclusive de trancher toute controverse résultant de, ou connecté à, ces Conditions générales relève des juridictions du lieu où le Propriétaire est basé, tel que décrit dans la section pertinente de ce document.{"\n\n\n\n"}

            <div className='bold '>
                Exception pour les Consommateurs situés en Europe{"\n\n"}
            </div>

            Ce qui précède ne s’applique pas aux Utilisateurs qui sont qualifiés de Consommateurs Européens, ni aux Consommateurs basés au Royaume-Uni, Norvège ou Islande.{"\n\n\n\n"}

            <div className='bold '>
                Résolution de conflits{"\n\n"}
            </div>


            <div className='bold '>
                Résolution à l’amiable de différends{"\n\n"}
            </div>
            
            Les Utilisateurs peuvent porter un conflit au Propriétaire qui tentera de le résoudre à l’amiable.

            Tandis que les droits des utilisateurs de prendre une action légale restera toujours intouché, dans le cas où toute controverse concernant l’utilisation de cette Application ou du Service, les Utilisateurs seront priés de contacter le Propriétaire avec les coordonnées fournies dans ce document.

            L’Utilisateur peut adresser au Propriétaire, à l’adresse électronique indiquée dans le présent document, une plainte comprenant une brève description et le cas échéant, les informations relatives à la commande, à l’achat, ou au compte concernés.

            Le Propriétaire traitera la plainte sans délai injustifié et sous 2 jours à compter de sa réception.{"\n\n\n\n"}

            {/*<div className='bold '>Règlement en ligne des litiges pour les Consommateurs{"\n\n"}</div>
            
            La Commission européenne a établi une plateforme en ligne pour les règlements de conflits alternatifs, qui offre un mode de règlement extrajudiciaire des litiges liés à et découlant de contrats de ventes et de services en ligne.

            Ainsi, tout Consommateur Européen ou tout Consommateur situé en Norvège, en Islande ou au Liechtenstein peut utiliser une telle plateforme pour résoudre les litiges découlant de contrats qui ont été conclus en ligne. Cette plateforme est accessible en suivant le lien suivant.
            */}
        </div>
    );
}

export default GeneralConditionsPage;