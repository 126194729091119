import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import BackOfficeMainPage from './pages/BackOfficeMainPage';
import PasswordForgottenPage from './pages/PasswordForgottenScreen';
import PasswordForgottenPage2 from './pages/PasswordForgottenScreen2';
import LandingPage from './pages/LandingPage';
import LoginScreenRouteSetter from './pages/LoginScreenRouteSetter';
import RedirectStorePage from './pages/RedirectStoreScreen';
import SpecialNightScreen from './pages/SpecialNightScreen';
import { GlobalProvider } from './context/GlobalStateProvider';
import HomePageBO from './pages/HomePageBO';
import SectorsDetailsPage from './pages/SectorsDetailsPage';
import ParametersPage from './pages/ParametersPage';
import CircuitsOverviewPage from './pages/CircuitsOverviewPage';
import CircuitsDetailsPage from './pages/CircuitsDetailsPage';
import PasswordResetPage from './pages/PasswordResetPage';
import CommentsPage from './pages/CommentsPage';
import ChallengesScreen from './pages/ChallengesScreen';
import ContestScreen from './pages/ContestsScreen';
import AleaSortingScreen from './pages/AleaSorting';
import GeneralConditionsPage from './pages/GeneralConditionsPage';
import UserDeletionPage from './pages/UserDeletionPage';
import PersonnalDataPage from './pages/PersonnalDataPage';
import ContestMinorScreen from './pages/ContestsMinorScreen';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GlobalProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage/>} />
          <Route path='/login-pro' element={<LoginScreenRouteSetter/>} />
          <Route path='/homepagebo' element={<HomePageBO/>} />
          <Route path='/sectors-details' element={<SectorsDetailsPage/>} />
          <Route path='/comments' element={<CommentsPage/>} />
          <Route path='/circuits-overview' element={<CircuitsOverviewPage />} />
          <Route path='/circuits-details' element={<CircuitsDetailsPage />} />
          <Route path='/bomain' element={<BackOfficeMainPage/>} />
          <Route path='/password-reset' element={<PasswordResetPage/>} />
          <Route path='/password-forgotten/' element={<PasswordForgottenPage/>} />
          <Route path='/password-changed/' element={<PasswordForgottenPage2/>} />
          <Route path='/redirect-store/' element={<RedirectStorePage />}/>
          <Route path='/defi-gaston' element={<ChallengesScreen />}/>
          <Route path='/contest' element={<ContestScreen />}/>
          <Route path='/contest-junior' element={<ContestMinorScreen />}/>

          <Route path='/alea-sorting' element={<AleaSortingScreen />}/>
          <Route path='/parameters-admin' element={<ParametersPage />}/>
          <Route path='/general-conditions' element={<GeneralConditionsPage />}/>
          <Route path='/personnal-data' element={<PersonnalDataPage />}/>
          <Route path='/user-deletion-infos' element={<UserDeletionPage />}/>


        </Routes>
      </BrowserRouter>
    </GlobalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
