import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { httpToUse, ipAdress, localIpAdress, portToUse } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButtonBO';
import CustomButtonBO from '../components/UI/CustomButtonBO';

const UserDeletionPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const uid = queryParams.get('uid');
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmationShown, setPasswordConfirmationShown] = useState(false);
    const navigate = useNavigate()
    
    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const togglePasswordConfirmationVisibility = () => {
        setPasswordConfirmationShown(!passwordConfirmationShown);
    };

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
            .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
            .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une majuscule')
            .matches(/[0-9]/, 'le mot de passe doit contenir au moins un chiffre')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Le mot de passe doit contenir au moins un caractère spécial')
            .required('Le mot de passe est requis'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], "La confirmation du mot de passe n'est pas identique")
                .required('La confirmation du mot de passe est requise')
        }),
        onSubmit: (values) => {
            // Replace with your API endpoint and necessary payload
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken
            axios.post(`https://rotpunkttest.fr/api/change-password/?token=${token}&uid=${uid}`, {
            new_password: values.newPassword,
            confirm_password: values.confirmPassword
        })
        .then(response => {
            navigate('/password-changed/')
            // Redirect or perform other actions upon success
        })
        .catch(error => {
            console.error('Error updating password', error);
        });
        },
    });

    return (
        <div className='general_conditions_outercontainer'>
                <div className='bold '>
                    Supprimer son compte Gaston{"\n\n"} 
                </div>
                <div>
                    Sur l'application Gaston :
                </div>
                <div>
                    Depuis la page de modification de profil, presser "Supprimer mon compte", cela supprimera ton compte Gaston et toutes les données qui te sont associées
                </div>
                <div>
                    Par mail :
                </div>
                <div>
                    Envoie un mail à l'adresse daniel@gaston-grimpe.com avec en objet : Suppression de compte et ton adresse mail
                </div>

                
        </div>
    );
}

export default UserDeletionPage;