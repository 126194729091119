import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { httpToUse, ipAdress, localIpAdress, portToUse } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButtonBO';
import CustomButtonBO from '../components/UI/CustomButtonBO';

const PersonnalDataPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const uid = queryParams.get('uid');
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmationShown, setPasswordConfirmationShown] = useState(false);
    const navigate = useNavigate()
    
    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const togglePasswordConfirmationVisibility = () => {
        setPasswordConfirmationShown(!passwordConfirmationShown);
    };

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
            .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
            .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une majuscule')
            .matches(/[0-9]/, 'le mot de passe doit contenir au moins un chiffre')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Le mot de passe doit contenir au moins un caractère spécial')
            .required('Le mot de passe est requis'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], "La confirmation du mot de passe n'est pas identique")
                .required('La confirmation du mot de passe est requise')
        }),
        onSubmit: (values) => {
            // Replace with your API endpoint and necessary payload
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken
            axios.post(`https://rotpunkttest.fr/api/change-password/?token=${token}&uid=${uid}`, {
            new_password: values.newPassword,
            confirm_password: values.confirmPassword
        })
        .then(response => {
            navigate('/password-changed/')
            // Redirect or perform other actions upon success
        })
        .catch(error => {
            console.error('Error updating password', error);
        });
        },
    });

    return (
        <div className='general_conditions_outercontainer'>
                <div className='bold '>
                Utilisation des données personnelles{"\n\n"} 
                </div>
                Suis-je concerné(e) ? :{"\n"}

                Si vous avez confié à votre établissement utilisant l’application de gestion de salle Gaston, ou à Gaston directement, des données personnelles vous concernant, vous êtes concernés par les traitements listés dans cette rubrique et disposez de tous les droits s’y attachant. Si vous utilisez les services de Gaston dans votre pratique de l’escalade, vous êtes également concernés par ces traitements.                
                <div className='bold '>
                    Quelles données recueillons-nous ?
                </div> 
                Gaston recueille les données que vous confiez à votre salle d’escalade lors de la création de votre compte client ou à Gaston lors de la création de votre compte client sur l’application : nom, prénoms, adresse électronique, numéro de téléphone, photos de profil. {"\n\n\n\n"}

                <div className='bold '>
                Que faisons-nous de ces données ?{"\n\n"}
                </div>
                Vos données servent à des fins statistiques, à la création de votre compte client, à votre identification à chaque usage de l’application. Vos données de contact permettent également de vous envoyer des offres promotionnelles ou commerciales. Afin d’affiner le choix d’offres qui vous est adressé et de vous éviter de crouler sous les mails indésirables, nous traitons automatiquement vos données pour ne vous adresser que les messages susceptibles de vous intéresser.{"\n\n\n\n"}

                <div className='bold '>
                Où sont stockées vos données ?{"\n\n"}
                </div>

                Ces données sont stockées chez nos partenaires, sur le territoire de l’Union Européenne. Tout transfert de données en dehors du territoire de l’Union Européenne est réalisé conformément au mécanisme de clauses contractuelles types, adopté par la Commission européenne par décision 2010/87/UE du 5 février 2010, qui assurent un haut niveau de sécurité de vos données. En aucun cas, nos partenaires stockant vos données ne pourront y accéder ou les utiliser d’une quelconque manière.{"\n\n\n\n"}


                <div className='bold '>
                Combien de temps ces données sont-elles conservées ?{"\n\n"}
                </div>
                Faute de renouvellement, vos données sont intégralement effacées dans un délai de 16 ans à compter de votre dernier passage dans un établissement utilisant l’Application Gaston, sauf si vous demandez leur suppression avant l’expiration d’un tel délai.{"\n\n\n\n"}
    
                <div className='bold '>
                Puis-je m’opposer à ces traitements ?{"\n\n"}
                </div>
                ‍Gaston est engagée dans le respect de la vie privée de chacun et vous offre la possibilité de vous vous opposer au traitement de tout ou partie de ces données, voire même de vous opposer à tout stockage de celles-ci. Vous trouverez en bas de page les modalités d’exercice de ce droit.{"\n\n\n\n"}

                <div className='bold '>
                Comment accéder à mes données ou contrôler leur utilisation ? {"\n\n"}
                </div>
                ‍Vous pouvez accéder librement à l’intégralité de vos données utilisées par Gaston, ou solliciter la rectification, l’effacement d’une partie ou de l’intégralité de celles-ci ; vous pouvez également contrôler quel traitement vous choisirez d’autoriser ou d’interdire et vous opposez à tout traitement automatisé de vos données. Celles-ci peuvent également vous être restituées si vous le désirez. Vous trouverez en bas de page les modalités d’exercice de ce droit.{"\n\n\n\n"}
                
                <div className='bold '>
                Quels sont mes recours en cas de désaccord ?{"\n\n"}
                </div>
                Si vous êtes insatisfait par les réponses apportées par Gaston relativement à l’utilisation de vos données, vous pouvez vous adresser à la Commission Nationale de l’Informatiques et des Libertés (CNIL).{"\n\n\n\n"}
    
                <div className='bold '>
                ‍Informations générales et exercice de vos droits {"\n\n"}
                </div>
                Responsable de Traitement : Daniel Marques de la société Gaston SAS, dont le siège social est situé 9, rue nouvelle, 74000, Annecy. Pour toute demande de précision quant à l’utilisation de vos données personnelles ou pour tout exercice de votre droit d’accès, de rectification ou d’effacement de vos données ; de votre droit de vous opposer au traitement, automatisé ou non, de vos données ou de limiter ce traitement, vous pouvez adresser votre requête à l’adresse électronique suivante : daniel@gaston-grimpe.com{"\n\n\n\n"}

                
        </div>
    );
}

export default PersonnalDataPage;